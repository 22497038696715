import {Box, Container, Divider, Link, Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Attribution = () => {
  const { t } = useTranslation();

  const translationAttribution = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {t('attribution.translatedBy')}{' '}
        <Link href={t('attribution.translatorLink')}>
          {t('attribution.translatorName')}
        </Link>
      </Typography>
    );
  };

  return (
    <Container>
      <Divider />
      <Typography
        margin={4}
        variant="body2"
        color="textSecondary"
        align="center"
      >
          <Link href="https://github.com/jhaals/yopass" target="_blank">
              <Box
                  sx={{
                      verticalAlign: 'middle',
                      width: '40px',
                      height: '40px',
                  }}
                  component="img"
                  margin={2}

                  height="40"
                  alt=""
                  src="yopass.svg"
              />
          </Link>
          <br/>
        {t('attribution.createdBy')}{' '}
        <Link href="https://github.com/jhaals/yopass" target="_blank">Johan Haals</Link>
      </Typography>
      {t('attribution.translatorName') && translationAttribution()}
    </Container>
  );
};
